import type { MasterProduct, Product } from "~/types/common";

const round = (price: number, precision: number = 2) => {
  const conversion = Math.pow(10, precision);

  return Math.round(price * conversion) / conversion;
};

export function useGrossPriceCalculator() {
  const getVariantPrice = (variant: Product): number => {
    // console.log("useGrossPriceCalculator::getVariantPrice()");
    // FIXME: apply the final logic once MNTX-158 is resolved
    return parseFloat(variant.product.original_price);
  };

  const getVariantDiscount = (variant: Product): number => {
    // FIXME: apply the final logic once MNTX-158 is resolved
    return (
      parseFloat(variant.product.original_price) -
      parseFloat(variant.product.price)
    );
  };

  const getProductMinimumPrice = (product: MasterProduct): number => {
    // console.log("useGrossPriceCalculator::getProductMinimumPrice()");
    if (product.products.length === 0) {
      return 0;
    }

    let minimumPrice = Number.MAX_VALUE;

    for (const variant of product.products) {
      const variantPrice = getVariantPrice(variant);

      if (variantPrice < minimumPrice) {
        minimumPrice = variantPrice;
      }
    }

    return minimumPrice;
  };

  return {
    getVariantPrice,
    getVariantDiscount,
    getProductMinimumPrice,
  };
}

export function useNetPriceCalculator() {
  const getDivider = (): number => {
    return 1 + parseFloat(useRuntimeConfig().public.vat) / 100;
  };

  const getVariantPrice = (variant: Product): number => {
    return round(
      useGrossPriceCalculator().getVariantPrice(variant) / getDivider(),
    );
  };

  const getVariantDiscount = (variant: Product): number => {
    return round(
      useGrossPriceCalculator().getVariantDiscount(variant) / getDivider(),
    );
  };

  const getProductMinimumPrice = (product: MasterProduct): number => {
    return round(
      useGrossPriceCalculator().getProductMinimumPrice(product) / getDivider(),
    );
  };

  return {
    getVariantPrice,
    getVariantDiscount,
    getProductMinimumPrice,
  };
}

export function usePriceCalculator() {
  const getDivider = (): number => {
    return 1 + parseFloat(useRuntimeConfig().public.vat) / 100;
  };

  const getGrossValue = (netValue: number): number => {
    return round(netValue * getDivider());
  };

  const getNetValue = (grossValue: number): number => {
    return round(grossValue / getDivider());
  };

  const getTaxValue = (grossValue: number): number => {
    // TODO: to be finished
    return round(grossValue - getNetValue(grossValue));
  };

  return {
    getGrossValue,
    getNetValue,
    getTaxValue,
  };
}
